import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/default';
import InteractiveTour from '../../../components/InteractiveTour';
export const _frontmatter = {
  "product": "signatures",
  "category": "Getting Started",
  "sort": 3,
  "title": "Interactive tour",
  "subtitle": "Take an interactive tour through the GraphQL queries/mutations required to setup a signature flow."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InteractiveTour mdxType="InteractiveTour" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      